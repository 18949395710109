import React from "react";
import { Button, Dialog, DialogContent, DialogProps, DialogTitle, Stack, Typography } from "@mui/material";
import GoldButton from "../dumps/GoldButton";
import { useAccount } from "../providers/AccountProvider";
import { useModelPrice } from "../../hooks/useModelPrices";
import { useModel } from "../providers/ModelProvider";
import cfg from "../../config";

export type RequestCallDialogProps = DialogProps & {
  onRequested: () => void;
  onCancel: () => void;
}

const RequestCallDialog: React.FC<RequestCallDialogProps> = ({ onRequested, onCancel, ...props }) => {
  const { ballance } = useAccount();
  const { modelId } = useModel();
  const videoPrice = useModelPrice(modelId as string, 'videoCall');
  
  return (
    <Dialog fullScreen sx={{  }} {...props}>
      <DialogTitle>
        <Typography variant='h4' align='center'>
          Video Call
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography align='center' variant="h6">
          Are you sure you want to start a video call with this model?
        </Typography>
        <Typography align='center' mt={2}>
          {`The first 15 minutes will cost you ${videoPrice?.price || 1500} tokens (minimum 15 minutes).`}
        </Typography>
        <Typography align='center'>
          Each additional minute is 100 tokens.
        </Typography>
      </DialogContent>
      {
        videoPrice && ballance < videoPrice?.price && (
          <DialogContent>
            <Typography align='center' color='error' fontWeight={600}>
              You don't have enough tokens to start a video call.
            </Typography>
            <Typography align='center' color='error' fontWeight={600}>
              {`Your current balance is ${ballance} tokens.`}
            </Typography>
          </DialogContent>
        )
      }
      <Stack spacing={2} width='90%' alignSelf='center' pb={2}>
        <GoldButton
          variant='contained'
          onClick={onRequested}
          disabled={!videoPrice || ballance < videoPrice.price}
        >
          Request Call
        </GoldButton>
        <Button
          variant='contained'
          onClick={onCancel}
          sx={{
            minHeight: '56px',
            fontSize: '16px',
            fontWeight: 600,
          }}
        >
          Cancel
        </Button>
      </Stack>
    </Dialog>
  );
}

export default React.memo(RequestCallDialog);