import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, ListProps, Typography } from '@mui/material';
import BlurPaper from '../dumps/BlutPaper';
import { useModel } from '../providers/ModelProvider';
import MessageIcon from '@mui/icons-material/Message';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import VideocamIcon from '@mui/icons-material/Videocam';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import useModelPrices from '../../hooks/useModelPrices';

const PriceIcon = ({ type }: { type: string }) => {
  switch (type) {
    case 'message':
      return <MessageIcon />;
    case 'sticker':
      return <EmojiEmotionsIcon />;
    case 'photo':
      return <PhotoCameraIcon />;
    case 'video':
      return <VideocamIcon />;
    default:
      return <MessageIcon />;
  }
}


type ModelPricesProps = ListProps;
const ModelPrices: React.FC<ModelPricesProps> = (props) => {
  const { modelId } = useModel();
  const prices = useModelPrices(modelId);

  return (
    <List sx={{ width: '100%' }} {...props}>
      {prices.map((item, index) => (
        <BlurPaper key={item.id} duration={`${index * 0.1}s`}>
          <ListItem dense>
            <ListItemIcon>
              <PriceIcon type={item.id} />
            </ListItemIcon>
            <ListItemText>
              <Typography>
                {item.title}
              </Typography>
            </ListItemText>
            <ListItemIcon>
              <Typography >
                {`${item.price} tokens`}
              </Typography>
            </ListItemIcon>
          </ListItem>
        </BlurPaper>
      ))}
    </List>
  );
};

export default ModelPrices;