import React from 'react';
import { styled } from '@mui/system';
import { Avatar, Box, Typography } from '@mui/material';
import BlurPaper from '../../../components/dumps/BlutPaper';
import { useParams } from 'react-router-dom';
import { ModelProvider } from '../../../components/providers/ModelProvider';
import PayButton from '../../../components/containers/PayButton';
import MobilePage from '../../../components/layout/MobilePage';
import AccountBallance from '../../../components/containers/account/AccountBallance';
import ModelName from '../../../components/containers/model/ModelName';
import ModelAvatar from '../../../components/containers/model/ModelAvatar';
import { TonConnectButton } from '@tonconnect/ui-react';
import ModelPrices from '../../../components/containers/ModelPrices';
import SubscribeButton from '../../../components/containers/model/SubscribeButton';
import VideoCallButton from '../../../components/containers/model/VideoCallButton';
import ModelStatus from '../../../components/containers/model/ModelStatus';
import { IsPayments } from '../../../components/containers/IsPayments';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
}));

const ModelPage: React.FC = () => {
  const { alias } = useParams<{ alias: string }>();
  return (
    <ModelProvider alias={alias as string}>
      <MobilePage
        hideBottomNav
        headerHeight={12}
        header={(
          <BlurPaper duration='0.25s' sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box display='flex' flexDirection='row' alignItems='center' gap={2} justifyContent='center' width='100%'>
              <Avatar src='/logo128.webp' sx={{ width: 50, height: 50 }} />
              <AccountBallance variant='h6' fontSize={24} textAlign='center' />
            </Box>
          </BlurPaper>
        )}
      >
        <Root>
          <Box p={2} display='flex' flexDirection='column' justifyContent='center' alignItems='center' width='100%' gap={2}>
            <Typography variant='h4'>Wallet</Typography>
            <TonConnectButton />
          </Box>
          <PayButton variant='contained' fullWidth sx={{ mb: 6 }} />
          <BlurPaper duration='0.25s'>
            <ModelAvatar />
            <ModelName />
            <ModelStatus />
            <SubscribeButton />
            <VideoCallButton />
          </BlurPaper>
          <IsPayments>
            <ModelPrices />
          </IsPayments>
        </Root>
      </MobilePage>
    </ModelProvider>
  );
};

export default ModelPage;
